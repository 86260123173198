import { PAYWALL_TYPE } from '#/packages/subscription/types/Checkout';
import { Button, Modal } from '#/packages/ui';
import { NavButton } from '#/src/components/NavButton';

const PAYWALL_DATA = {
  [PAYWALL_TYPE.CONTENT_ITEMS_LIMIT]: {
    title: 'Create unlimited reports',
    description:
      'Upgrade to Superads Professional to create unlimited reports and unlock additional features for your workspace.'
  },
  [PAYWALL_TYPE.MULTI_ACCOUNT]: {
    title: 'Get multi-account analysis',
    description:
      'Upgrade to Superads Professional to analyze multiple accounts at the same time and unlock additional features for your workspace.'
  },
  [PAYWALL_TYPE.AI_DIMENSION]: {
    title: 'Get access to AI-analyzed dimensions',
    description:
      'Upgrade to Superads Professional to get AI-analyzed dimensions and unlock additional features for your workspace.'
  }
};

export const ContentItemLimitModal: React.FC<{
  type?: PAYWALL_TYPE;
  onClose: () => void;
}> = ({ type = PAYWALL_TYPE.CONTENT_ITEMS_LIMIT, onClose }) => {
  return (
    <Modal
      title={PAYWALL_DATA[type]?.title}
      size={'lg'}
      show={true}
      onClose={onClose}
      overflowHidden={false}
      dialogClassName={'overflow-y-visible'}
    >
      <div className='flex flex-col gap-6'>
        <span className='leading-6 text-sm'>{PAYWALL_DATA[type]?.description}</span>

        <div className='flex gap-2 justify-end'>
          <Button
            weight={'solid'}
            size={'xl'}
            color={'callout'}
            onClick={onClose}
            className={'justify-center w-fit'}
          >
            Cancel
          </Button>
          <NavButton
            weight={'solid'}
            size={'xl'}
            color={'primary'}
            to={`/settings/upgrade`}
            className={'justify-center w-fit'}
            onClick={onClose}
          >
            Try 1 month for free
          </NavButton>
        </div>
      </div>
    </Modal>
  );
};
